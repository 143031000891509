import IconButton from "common/buttons/IconButton";
import { CloseIcon, Logo } from "assets";
import { useDispatch } from "react-redux";
import { topBarReducer } from "store/app/appSlice";

const Topbar = () => {
	const dispatch = useDispatch();
	return (
		<div className='columns m-0 is-mobile has-background-primary is-flex is-align-items-center'>
			{/* app icon */}
			<div className='column is-3 p-0 logo-height'>
				<Logo />
			</div>
			{/* main text */}
			<div className='column is-6'>
				<h6 className='has-text-weight-normal has-text-black font-size-1'>
					MilkBun
				</h6>
				<h6 className='has-text-weight-normal has-text-black font-size-1'>
					Download App Now!
				</h6>
				<small className='has-text-black font-size-2'>
					Get - On the App Store
				</small>
			</div>
			{/* redirect and close */}
			<div className='column is-3 m-0 p-0 is-flex is-justify-content-space-between is-flex-direction-column'>
				<IconButton
					icon={<CloseIcon />}
					onClick={() => dispatch(topBarReducer())}
				/>
				<a href>
					<h6 className='has-text-black has-text-weight-normal font-size-1'>
						View
					</h6>
				</a>
			</div>
		</div>
	);
};

export default Topbar;
