import { lazy } from "react";

export const ROUTES = [
	{
		name: "Dashboard",
		path: "/",
		component: lazy(() => import("pages/dashboardPage/DashboardPage")),
	},
	{
		name: "Customise order",
		path: "/customise-order",
		component: lazy(() =>
			import("pages/customiseOrderPage/CustomiseOrderPage")
		),
	},
	{
		name: "Checkout",
		path: "/checkout",
		component: lazy(() => import("pages/checkoutPage/CheckoutPage")),
	},
	{
		name: "Terms and conditions",
		path: "/terms-and-conditions",
		component: lazy(() => import("pages/tNcPage/TnCPage")),
	},
	{
		name: "Success",
		path: "/success",
		component: lazy(() => import("pages/SuccessPage")),
	},
	{
		name: "Failure",
		path: "/failure",
		component: lazy(() => import("pages/FailurePage")),
	},
	{
		name: "Payment page",
		path: "/payment-page",
		component: lazy(() => import("pages/PaymentPage")),
	},
];
