import { NotFound404 } from "common";
import LoginPage from "pages/authPage/LoginPage";
import { Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";
import { reduxDataAuth } from "store/auth/authSlice";
import { reduxDataUser } from "store/user/userSlice";
import { ROUTES } from "routes/Routes";

const AppRoutes = () => {
	const { isLoggedIn } = useSelector(reduxDataAuth);
	const { reservationStatus } = useSelector(reduxDataUser);

	return (
		<Suspense fallback={"Loading. Please wait..."}>
			<Routes>
				<Route
					path='/login'
					element={
						Object.keys(reservationStatus).length && isLoggedIn ? (
							<Navigate to='/' />
						) : (
							<LoginPage />
						)
					}
				/>

				{ROUTES?.map((route, index) => {
					return route.component ? (
						<Route
							key={index}
							path={route?.path}
							name={route?.name}
							element={
								isLoggedIn ? <route.component /> : <Navigate to='/login' />
							}
						/>
					) : null;
				})}

				<Route path='*' element={<NotFound404 />} />
			</Routes>
		</Suspense>
	);
};
export default AppRoutes;
