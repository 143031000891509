import React from "react";
import { CHOICES } from "constants/CHOICES";
import { UpgradeButton } from "common";

const CustomiseCard = () => {
	return (
		<div>
			<div className='column'>
				{" "}
				<h6 className='is-size-6 has-text-weight-medium'>Choose Optionals</h6>
			</div>

			{/* upgrade options */}
			<div className='columns is-mobile m-0  '>
				<div className='column is-9'>
					{" "}
					<h6 className='is-size-6 '>Upgrade your burger with</h6>
				</div>
				<div className='column is-3 pl-0'>
					{" "}
					<span className='tag is-primary is-rounded has-text-black'>
						REQUIRED{" "}
					</span>
				</div>
			</div>
			{/* buttons for upgrading */}
			<div className='columns m-0 is-mobile is-flex is-justify-content-space-evenly'>
				<UpgradeButton patty='Double patty' price={2} active />
				<UpgradeButton patty='Triple patty' price={4} />
				<UpgradeButton patty='Quad patty' price={6} />
			</div>
			<br />

			<hr className='divider' />
			<br />
			{/* add choices*/}
			<div className='columns is-mobile m-0 '>
				<div className='column is-9'>Add Choice</div>
				<div className='column is-3 pl-0'>
					{" "}
					<span className='tag is-primary is-rounded has-text-black'>
						REQUIRED{" "}
					</span>
				</div>
			</div>

			{/* choices */}
			<div className='columns  m-0 '>
				<div className='column'>
					{CHOICES.map((choice) => (
						<div
							className='columns is-mobile is-flex is-justify-content-space-between'
							key={choice.id}>
							<div className='column '>
								<label className='checkbox'>
									<input type='checkbox' checked={choice.id === 0} />
									&nbsp;{choice.name}
								</label>
							</div>
							<div className='column is-4  is-flex is-justify-content-center '>
								{choice.amount}
							</div>
						</div>
					))}
				</div>
				<br />
				<hr className='divider' />
				<br />
			</div>

			{/* remove choices*/}
			<div className='columns is-mobile m-0 '>
				<div className='column is-9'>Remove Choice </div>
				<div className='column is-3 pl-0'>
					<span className='tag is-primary is-rounded has-text-black'>
						REQUIRED{" "}
					</span>
				</div>
			</div>

			{/*  choices */}
			<div className='columns  m-0 '>
				<div className='column'>
					{CHOICES.map((choice) => (
						<div
							className='columns is-mobile is-flex is-justify-content-space-between'
							key={choice.id}>
							<div className='column '>
								<label className='checkbox'>
									<input type='checkbox' />
									&nbsp;{choice.name}
								</label>
							</div>
							<div className='column is-4  is-flex is-justify-content-center '>
								{choice.amount}
							</div>
						</div>
					))}
				</div>
				<br />
				<hr className='divider' />
				<br />
			</div>
		</div>
	);
};

export default CustomiseCard;
