import { CocopalmsIcon, TandCIcon } from "assets";
import { Link } from "react-router-dom";

const Footer = () => (
	<div className='columns m-0 has-background-primary is-mobile '>
		<div className='column m-0 is-5 ml-3'>
			<h6 className=' ultra-small-font is-flex is-align-items-center'>
				Powered By : <CocopalmsIcon />
			</h6>
		</div>
		<div className='column m-0 is-flex is-justify-content-flex-end is-6 pr-0'>
			<Link to={"/terms-and-conditions"}>
				<h6 className='has-font-weight-medium has-text-dark  ultra-small-font'>
					<TandCIcon /> Terms & Conditions
				</h6>
			</Link>
		</div>
	</div>
);

export default Footer;
