import axios from "axios";
import API_ENDPOINTS from "constants/API_ENDPOINTS";

const client = axios.create({
	baseURL: API_ENDPOINTS.BACKEND_SERVER_ADDRESS,
	headers: {
		// 'X-Custom-Header': 'foobar',
		accept: "application/json",
		// "content-type": "application/json",
		"content-type": "application/x-www-form-urlencoded",
	},
	params: {
		// ID: 12345
	},
});

export const request = ({ ...options }) => {
	//? TODO: get AUTH_TOKEN from localStorage or cookie
	// const AUTH_TOKEN = localStorage.getItem("access_token");
	client.defaults.headers.common.Authorization =
		localStorage.getItem("access_token");

	const onSuccess = (response) => response;
	const onError = (error) => error;

	return client(options).then(onSuccess).catch(onError);
};
