import { MenuCard } from "common";
import { useProducts } from "hooks/useProducts";

const Dashboard = () => {
	const { data } = useProducts();

	return (
		<div className='m-3'>
			{data?.data?.map((item) => {
				return <MenuCard key={item.id} item={item} />;
			})}
		</div>
	);
};

export default Dashboard;
