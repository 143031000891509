import { WaiterIcon } from "assets";
import IconButton from "./IconButton";
import { useDispatch } from "react-redux";
import { callWaiterModalReducer } from "store/modals/modalsSlice";

const CallWaiterButton = () => {
	const dispatch = useDispatch();

	return (
		<div className='columns  m-0'>
			<div className='column  p-0 m-0 is-flex is-justify-content-center'>
				<a href onClick={() => dispatch(callWaiterModalReducer())}>
					<IconButton icon={<WaiterIcon />} color='primary' />
				</a>
			</div>
			<div className='column  p-0 m-0  is-flex is-justify-content-center'>
				<small style={{ fontSize: ".6rem" }} className='has-text-weight-bold'>
					CALL WAITER
				</small>
			</div>
		</div>
	);
};

export default CallWaiterButton;
