import { CloseIcon, DeleteIcon, EditIcon } from "assets";

import Card from "./Card";
import { IconButton, PlusMinusPair } from "common";
import { useState, useEffect } from "react";
import useCart from "hooks/useCart";

const CartItemCard = ({ item }) => {
	const { handleAddInstructions } = useCart();

	const [notes, setNotes] = useState("");
	const { handleIncrement, handleDecrement, handleRemoveFromCart } = useCart();

	const [openInstructionField, setOpenInstructionField] = useState(false);
	const { product_image, name, description, product_price, quantity } = item;

	useEffect(() => {
		handleAddInstructions(item, notes);
	}, [notes]);

	const handleNotesChange = (e) => {
		setNotes(e.target.value);
	};

	return (
		<Card>
			<div className='columns m-0 is-mobile'>
				{/* image column */}
				<div className='column is-5 is-flex is-align-items-center'>
					<img src={product_image} alt={name} />
				</div>
				{/* item description  column */}
				<div className='column'>
					<div className='columns m-0 is-flex is-justify-content-space-between'>
						<h6 className='is-size-6 has-text-weight-semibold has-text-primary '>
							{name}
						</h6>
						<h6 className='is-size-7 has-text-weight-semibold '>edit</h6>
					</div>

					<h6 className='is-size-7 has-text-weight-light py-1'>
						{description}
					</h6>

					<h6 className='is-size-6 has-text-weight-semibold has-text-primary py-2 '>
						KWD {product_price}
					</h6>

					{/* button */}
					<div className='columns m-0 is-mobile is-flex is-align-items-center  '>
						<PlusMinusPair
							count={quantity}
							plusFunction={() => handleIncrement(item)}
							minusFunction={() => handleDecrement(item)}
						/>

						<div className='column p-0 is-flex is-justify-content-space-between'>
							<IconButton
								color='dark'
								icon={openInstructionField ? <CloseIcon /> : <EditIcon />}
								onClick={() => setOpenInstructionField(!openInstructionField)}
							/>
							<IconButton
								color='primary'
								icon={<DeleteIcon />}
								onClick={() => {
									handleRemoveFromCart(item);
								}}
							/>
						</div>
					</div>
				</div>
			</div>
			{openInstructionField && (
				<div className='columns m-0 is-mobile mx-2 py-4'>
					<textarea
						onChange={handleNotesChange}
						style={{ borderRadius: "10px" }}
						className='textarea'
						placeholder='Start typing cooking instructions'
					/>
				</div>
			)}
		</Card>
	);
};

export default CartItemCard;
