import { CartIcon, DeleteIcon } from "assets";
import Button from "../buttons/Button";
import Card from "./Card";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, PlusMinusPair } from "common";
import { useNavigate } from "react-router-dom";

import { reduxDataCart, customiseItemReducer } from "store/cart/cartSlice";
import useCart from "../../hooks/useCart";

const MenuCard = ({ item }) => {
	const {
		handleAddToCart,
		handleIncrement,
		handleDecrement,
		handleRemoveFromCart,
	} = useCart();

	const navigate = useNavigate();
	const { cartItems } = useSelector(reduxDataCart);
	const dispatch = useDispatch();
	const { id, product_image, name, description, product_price } = item;

	const found = cartItems.find((element) => element.id === id);

	return (
		<Card>
			<div className='columns m-0 is-mobile'>
				{/* product_image column */}
				<div className='column is-5 is-flex is-align-items-center'>
					<img src={product_image} alt={name} />
				</div>
				{/* item description  column */}
				<div className='column'>
					<h6 className='is-size-6 has-text-weight-semibold has-text-primary '>
						{name}
					</h6>

					<h6 className='is-size-7 has-text-weight-light py-1'>
						{description}
					</h6>

					<h6 className='is-size-6 has-text-weight-semibold has-text-primary py-2 '>
						KWD {product_price}
					</h6>

					{/* add to cart and increment decrement button */}
					<div className='columns m-0 is-mobile is-flex is-align-items-center is-justify-content-space-between'>
						{found !== undefined ? (
							<PlusMinusPair
								count={found.quantity}
								minusFunction={() => handleDecrement(item)}
								plusFunction={() => handleIncrement(item)}
							/>
						) : (
							<Button
								hasIcon
								icon={<CartIcon />}
								btnText={"Add to cart"}
								size={"small"}
								className={"is-rounded"}
								onClick={() => handleAddToCart(item)}
							/>
						)}

						{/* remove item from cart button */}
						{found ? (
							<div className='column is-3'>
								<IconButton
									color='primary'
									icon={<DeleteIcon />}
									onClick={() => {
										handleRemoveFromCart(item);
									}}
								/>
							</div>
						) : (
							<a
								href
								onClick={() => {
									dispatch(customiseItemReducer(item));
									navigate("/customise-order");
								}}>
								<small className='has-text-primary ultra-small-font'>
									Customisable
								</small>
							</a>
						)}
					</div>
				</div>
			</div>
		</Card>
	);
};

export default MenuCard;
