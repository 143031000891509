import { PattyIcon, SmallCheckIcon } from "assets";
import React from "react";

const UpgradeButton = ({ patty, price, active }) => {
	return (
		<button
			className={`upgrade-button  ${
				active ? "active-btn" : "has-background-white"
			}`}>
			<div className='columns m-0 px-3 is-mobile is-flex is-justify-content-space-between'>
				<PattyIcon />
				{active ? <SmallCheckIcon /> : null}
			</div>
			<br />

			<div className='columns m-0'>{patty}</div>
			<div className='columns m-0'>+ KWD {price}</div>
		</button>
	);
};

export default UpgradeButton;
