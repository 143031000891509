import { Button, Modal } from "common";
import { Footer } from "component";
import { BrowserRouter, Link } from "react-router-dom";
import AppRoutes from "routes/AppRoutes";
import { useSelector, useDispatch } from "react-redux";
import { reduxDataModals } from "./store/modals/modalsSlice";
import {
	paymentOptionsModalReducer,
	callWaiterModalReducer,
} from "store/modals/modalsSlice";
import { WaiterOnWayIcon } from "assets";
import useCallWaiter from "hooks/useCallWaiter";

const App = () => {
	const dispatch = useDispatch();
	const { isWaiterModalOpen, isPaymentOptionsModalOpen } =
		useSelector(reduxDataModals);

	useCallWaiter({
		isWaiterModalOpen,
	});

	return (
		<BrowserRouter>
			<AppRoutes />
			<Footer />

			{/* //!CALL WAITER MODAL */}
			<Modal whichModalToClose={"waiter"} modalState={isWaiterModalOpen}>
				<>
					<div className='is-flex is-justify-content-center'>
						<WaiterOnWayIcon />
					</div>

					<h6 className=' has-text-weight-light has-text-centered mt-3 mb-3'>
						Waiter is on the way!
					</h6>

					<div className='is-flex is-justify-content-center'>
						<Button
							btnText={"OK"}
							size='small'
							className={"is-rounded px-5"}
							onClick={() => dispatch(callWaiterModalReducer())}
						/>
					</div>
				</>
			</Modal>

			{/* //!SHOW PAYMENT METHODS MODAL */}
			<Modal modalState={isPaymentOptionsModalOpen} title={"Checkout"}>
				<>
					<h6 className='has-text-weight-light has-text-centered'>
						How would you like to pay?
					</h6>
					<div className='is-flex is-justify-content-center m-4'>
						<Link to='/payment-page'>
							<Button
								btnText='Card payment'
								className={"is-rounded"}
								onClick={() => dispatch(paymentOptionsModalReducer())}
							/>
						</Link>
					</div>

					<div className='is-flex is-justify-content-center m-4'>
						<Link to='/payment-page'>
							<Button
								btnText='Pay to waiter'
								className={"is-rounded"}
								onClick={() => dispatch(paymentOptionsModalReducer())}
							/>
						</Link>
					</div>
					<div className='is-flex is-justify-content-center m-4'>
						<Link to='/payment-page'>
							<Button
								btnText='Knet'
								className={"is-rounded"}
								onClick={() => dispatch(paymentOptionsModalReducer())}
							/>
						</Link>
					</div>
				</>
			</Modal>
		</BrowserRouter>
	);
};

export default App;
