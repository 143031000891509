const IconButton = ({
	icon,
	color = "transparent",
	size = "small",
	customSizing = false,
	customClass = "",
	isSquare,
	...rest
}) => {
	return (
		<button
			className={`button is-${size} ${customClass}  ${
				customSizing ? "custom-sizing" : ""
			}
		${color === "transparent" ? "transparent-icon-button" : `is-${color}`}
		${isSquare ? "square-border" : "circle-border"}
		`}
			{...rest}>
			<span className={`icon is-${size}  `}>{icon}</span>
		</button>
	);
};

export default IconButton;
