import { useDispatch, useSelector } from "react-redux";

import { reduxDataCart, cartItemsReducer } from "store/cart/cartSlice";

const useCart = () => {
	const { cartItems } = useSelector(reduxDataCart);
	const dispatch = useDispatch();

	//? function to add items to cart
	const handleAddToCart = (item) => {
		if (cartItems.find((element) => element.id === item.id)) return;
		let dataToAppendToArr = {
			...item,
			quantity: 1,
			bill_amount: item.product_price,
		};
		dispatch(cartItemsReducer([...cartItems, dataToAppendToArr]));
	};

	//? function to remove items from cart
	const handleRemoveFromCart = (item) => {
		if (cartItems.find((element) => element.id === item.id)) {
			let indexOfItemToRemove = cartItems.findIndex(
				(element) => element.id === item.id
			);
			let cartTemp = [...cartItems];
			cartTemp.splice(indexOfItemToRemove, 1);
			dispatch(cartItemsReducer([...cartTemp]));
		}
	};

	//? function to increase quantity of item in cart

	const handleIncrement = (item) => {
		let indexOfItemToIncrement = cartItems.findIndex(
			(element) => element.id === item.id
		);
		let cartTemp = [...cartItems];
		cartTemp[indexOfItemToIncrement] = {
			...cartTemp[indexOfItemToIncrement],
			quantity: cartTemp[indexOfItemToIncrement].quantity + 1,
			bill_amount:
				(cartTemp[indexOfItemToIncrement].quantity + 1) *
				cartTemp[indexOfItemToIncrement].product_price,
		};

		dispatch(cartItemsReducer(cartTemp));
	};

	//? function to decrease quantity of item in cart

	const handleDecrement = (item) => {
		let indexOfItemToDecrement = cartItems.findIndex(
			(element) => element.id === item.id
		);
		let cartTemp = [...cartItems];
		cartTemp[indexOfItemToDecrement] = {
			...cartTemp[indexOfItemToDecrement],
			quantity: cartTemp[indexOfItemToDecrement].quantity - 1,
			bill_amount:
				(cartTemp[indexOfItemToDecrement].quantity - 1) *
				cartTemp[indexOfItemToDecrement].product_price,
		};

		if (cartTemp[indexOfItemToDecrement].quantity > 0) {
			dispatch(cartItemsReducer(cartTemp));
		}
	};

	//? function to add notes in item

	const handleAddInstructions = (item, notes) => {
		let indexOfItemToAddInstructionsUnder = cartItems.findIndex(
			(element) => element.id === item.id
		);
		let cartTemp = [...cartItems];
		cartTemp[indexOfItemToAddInstructionsUnder] = {
			...cartTemp[indexOfItemToAddInstructionsUnder],
			notes,
		};
		dispatch(cartItemsReducer(cartTemp));
	};

	return {
		handleAddToCart,
		handleIncrement,
		handleDecrement,
		handleRemoveFromCart,
		handleAddInstructions,
	};
};

export default useCart;
