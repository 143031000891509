import React from "react";
import "./ButtonStyles.scss";

const Button = ({
	className,
	color = "info",
	size = "medium",
	btnText,
	hasIcon,
	icon,
	iconPosition,
	gradient,
	...rest
}) =>
	hasIcon ? (
		<button className={`button is-${color} is-${size} ${className}`} {...rest}>
			{iconPosition === "end" ? (
				<>
					<span>{btnText}</span>
					<span className='icon'>{icon}</span>
				</>
			) : (
				<>
					<span className='icon'>{icon}</span>
					<span>{btnText}</span>
				</>
			)}
		</button>
	) : (
		<button
			className={`button is-${color} is-${size} ${className} ${
				color === "info" ? "btn-text-black" : ""
			} `}
			{...rest}>
			{btnText}
		</button>
	);

export default Button;
