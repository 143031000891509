import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	isLoggedIn: localStorage.getItem("isLoggedIn") === "true" ? true : false,
	access_token: localStorage.getItem("access_token") ?? {},
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		//? this functions will be triggered by dispatch() whenever auth does any operation related to authentication
		loginReducer: (state, action) => {
			state.isLoggedIn = action.payload.isLoggedIn;
			state.access_token = `Bearer ${action.payload.tokens.access_token}`;
			localStorage.setItem(
				"access_token",
				`Bearer ${action.payload.tokens.access_token}`
			);
			localStorage.setItem("isLoggedIn", action.payload.isLoggedIn);
		},
	},
});
//// here we are basically exposing auth STATE's reducers  for use by components using the dispatch() from useDispatch hook

export const { loginReducer } = authSlice.actions;
//// here we are basically exposing auth STATE for use by components using the useSelector Hook
export const reduxDataAuth = (state) => state.auth;

export default authSlice.reducer;
