import API_ENDPOINTS from "constants/API_ENDPOINTS";
import { useQuery } from "react-query";
import { request } from "utils/axios";

// fetch categories function
const fetchProducts = () =>
	request({ url: API_ENDPOINTS.PRODUCTS, method: "get" });

const useProducts = () => useQuery("getting-list-of-products", fetchProducts);

export { useProducts };
