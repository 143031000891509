import { BackIcon, WaiterIcon } from "assets";
import {
	IconButton,
	LanguageDropdown,
	LargeButton,
	PlusMinusPair,
} from "common";
import { Topbar } from "component";

import { useSelector, useDispatch } from "react-redux";
import { reduxDataCart } from "store/cart/cartSlice";
import { Link } from "react-router-dom";
import { reduxDataApp } from "store/app/appSlice";
import CustomiseCard from "common/cards/CustomiseCard";
import { callWaiterModalReducer } from "store/modals/modalsSlice";
import useCart from "hooks/useCart";

const CustomiseOrder = () => {
	const dispatch = useDispatch();
	const { isTopbarOpen } = useSelector(reduxDataApp);
	const { customise } = useSelector(reduxDataCart);

	const { handleIncrement, handleDecrement } = useCart();

	return (
		<>
			{isTopbarOpen && <Topbar />}
			<div className='columns is-mobile px-3 mb-0 pt-1 is-flex is-align-items-center is-justify-content-space-between'>
				<div className='column pl-0 is-flex is-align-items-center  '>
					<Link to='/'>
						<IconButton size='medium' icon={<BackIcon />} />
					</Link>
				</div>
				<LanguageDropdown />
			</div>
			{/* customise product's product_image */}
			<div className='columns is-mobile m-0'>
				<img
					src={customise?.product_image}
					alt='milky'
					width={"100%"}
					height={"100%"}
				/>
			</div>
			{/* plus minus icon with call waiter */}
			<div className='columns m-0 is-mobile  is-flex is-justify-content-flex-end'>
				<PlusMinusPair
					count={0}
					minusFunction={() => handleDecrement(customise)}
					plusFunction={() => handleIncrement(customise)}
				/>
				<div className='column is-3 pt-0 pb-0  is-flex is-justify-content-flex-end'>
					<IconButton
						onClick={() => dispatch(callWaiterModalReducer())}
						color='primary'
						icon={<WaiterIcon />}
					/>
				</div>
			</div>
			{/* customise product's name and price */}
			<div className='columns is-mobile m-0 px-3 is-flex is-justify-content-space-between'>
				<div className='column is-7 is-flex is-justify-content-flex-start'>
					<h6 className='has-text-weight-medium has-text-black'>
						{customise?.name}
					</h6>
				</div>
				<div className='column is-4 pr-0 is-flex is-justify-content-flex-end'>
					<h6 className='has-text-weight-medium has-text-black'>
						{customise?.product_price}
					</h6>
				</div>
			</div>
			{/* customise product's description */}
			<div className='columns is-mobile m-0 px-3 is-flex is-justify-content-space-between'>
				<div className='column'>{customise?.description}</div>
			</div>

			{/* customise product card */}
			<CustomiseCard />
			{/* customise product card */}

			{/* action buttons */}
			<div className='columns is-flex m-0 is-justify-content-center px-3'>
				<Link to='/' style={{ width: "100%" }}>
					<button
						className='has-background-primary p-4 '
						style={{ width: "100%", borderRadius: "20px", border: "none" }}>
						<div className='columns is-flex m-0 is-justify-content-space-between'>
							<h6 className='is-size-6 '>
								Item total KWD {customise?.bill_amount}
							</h6>
							<h6 className='is-size-6 has-text-weight-medium'>Add to cart</h6>
						</div>
					</button>
				</Link>
			</div>
			<br />

			<Link to='/'>
				<LargeButton color='info' btnText={"Continue your order"} />
			</Link>
		</>
	);
};

export default CustomiseOrder;
