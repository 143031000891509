import {
	CallWaiterButton,
	CartItemCard,
	IconButton,
	LanguageDropdown,
	LargeButton,
} from "common";
import { useSelector, useDispatch } from "react-redux";
import { reduxDataCart } from "store/cart/cartSlice";
import { Topbar } from "component";
import { BackIcon } from "assets";

import { Link } from "react-router-dom";
import { reduxDataApp } from "store/app/appSlice";
import { paymentOptionsModalReducer } from "store/modals/modalsSlice";

const Checkout = () => {
	const { isTopbarOpen } = useSelector(reduxDataApp);
	const { cartItems } = useSelector(reduxDataCart);
	const dispatch = useDispatch();

	return (
		<>
			{isTopbarOpen && <Topbar />}

			{/* navigation */}
			<div className='columns is-mobile px-3 m-0 pt-1 is-flex is-align-items-center is-justify-content-space-between'>
				<div className='column pl-0 is-flex is-align-items-center  '>
					<Link to='/'>
						<IconButton size='medium' icon={<BackIcon />} />
					</Link>
					Checkout
				</div>

				<LanguageDropdown />
			</div>

			{/* waiter button */}

			<div className='columns px-3 is-mobile is-flex is-align-items-center'>
				<div className='column is-9'></div>
				<div className='column'>
					<CallWaiterButton />
				</div>
			</div>

			{/* end navigation */}

			{cartItems.length > 0 ? (
				<>
					<div className='m-3'>
						{cartItems.map((item) => (
							<CartItemCard item={item} />
						))}
					</div>

					{/* action buttons */}
					<div className='columns is-flex m-0 is-justify-content-center px-3'>
						<button
							className='has-background-primary p-4 '
							style={{ width: "100%", borderRadius: "20px", border: "none" }}
							onClick={() => dispatch(paymentOptionsModalReducer())}>
							<div className='columns is-flex m-0 is-justify-content-space-between'>
								<h6 className='is-size-6 '>
									Item total KWD&nbsp;
									{cartItems
										.map((item) => item.bill_amount)
										.reduce((prev, next) => prev + next)}
								</h6>
								<h6 className='is-size-6 has-text-weight-medium'>
									Place Order
								</h6>
							</div>
						</button>
					</div>
					<br />
					<Link to='/'>
						<LargeButton color='info' btnText={"Continue your order"} />
					</Link>
				</>
			) : (
				<>
					<h4 className='has-text-centered px-5 my-6 py-6'>
						You cart is empty please add items to proceed!
					</h4>
					<Link to='/'>
						{" "}
						<LargeButton color='info' btnText={"Browse Menu"} />
					</Link>
				</>
			)}
		</>
	);
};

export default Checkout;
