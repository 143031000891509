import API_ENDPOINTS from "constants/API_ENDPOINTS";
import { useQuery } from "react-query";
import { request } from "utils/axios";

// fetch categories function
const fetchCategories = () =>
	request({ url: API_ENDPOINTS.CATEGORIES, method: "get" });

const useCategories = () =>
	useQuery("getting-list-of-categories", fetchCategories);

export { useCategories };
