import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	isWaiterModalOpen: false,
	isPaymentOptionsModalOpen: false,
};

export const modalsSlice = createSlice({
	name: "modals",
	initialState,
	reducers: {
		//? this functions will be triggered by dispatch() whenever user clicks on call waiter button
		callWaiterModalReducer: (state) => {
			state.isWaiterModalOpen = !state.isWaiterModalOpen;
		},

		paymentOptionsModalReducer: (state) => {
			state.isPaymentOptionsModalOpen = !state.isPaymentOptionsModalOpen;
		},
	},
});

//// here we are basically exposing CART STATE's reducers  for use by components using the dispatch() from useDispatch hook
export const { callWaiterModalReducer, paymentOptionsModalReducer } =
	modalsSlice.actions;

//// here we are basically exposing CART STATE for use by components using the useSelector Hook
export const reduxDataModals = (state) => state.modals;
export default modalsSlice.reducer;
