import API_ENDPOINTS from "constants/API_ENDPOINTS";
import { useQuery } from "react-query";
import { request } from "utils/axios";

// call waiter
const callWaiter = () =>
	request({ url: API_ENDPOINTS.CALL_WAITERS, method: "get" });

const useCallWaiter = ({ isWaiterModalOpen }) =>
	useQuery("calling-waiter(s)", callWaiter, {
		// The query will not execute until the isWaiterModalOpen exists
		enabled: isWaiterModalOpen === true,
	});

export default useCallWaiter;
