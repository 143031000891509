import { configureStore } from "@reduxjs/toolkit";

import cartSlice from "./cart/cartSlice";
import appSlice from "./app/appSlice";
import authSlice from "./auth/authSlice";
import modalsSlice from "./modals/modalsSlice";
import userSlice from "./user/userSlice";

export const STORE = configureStore({
	reducer: {
		app: appSlice,
		cart: cartSlice,
		auth: authSlice,
		modals: modalsSlice,
		user: userSlice,
	},
});
