import API_ENDPOINTS from "constants/API_ENDPOINTS";

import { useDispatch } from "react-redux";

import { request } from "utils/axios";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { reservedTableReducer } from "store/user/userSlice";

const useReservation = () => {
	const navigate = useNavigate();

	const dispatch = useDispatch();

	// create reservation function
	const reserveTable = (data) =>
		request({
			url: API_ENDPOINTS.RESERVATIONS,
			method: "post",
			data,
		});

	// success callback of reservation function

	const onSuccess = (res) => {
		dispatch(reservedTableReducer({ ...res.data }));
		if (res.data.status === 0) {
			navigate("/");
		}
	};

	// error callback of reservation function

	const onError = () => {
		navigate("/login");
	};

	const useReserveTable = ({ tableData, isUserLoggedIn }) =>
		useQuery("reserve-table", () => reserveTable(tableData), {
			onSuccess,
			onError,
			enabled: isUserLoggedIn === true,
		});

	return { useReserveTable };
};

export default useReservation;
