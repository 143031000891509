import { CloseIconDark } from "assets";
import IconButton from "../buttons/IconButton";
import { useDispatch } from "react-redux";
import {
	callWaiterModalReducer,
	paymentOptionsModalReducer,
} from "store/modals/modalsSlice";

const Modal = ({ children, whichModalToClose, modalState, title }) => {
	const dispatch = useDispatch();

	if (!modalState) {
		return null;
	}

	return (
		<div className='modal is-active'>
			<div
				className='modal-background'
				onClick={() =>
					whichModalToClose === "waiter"
						? dispatch(callWaiterModalReducer())
						: dispatch(paymentOptionsModalReducer())
				}
			/>
			<div className='modal-card' style={{ width: "90%" }}>
				{whichModalToClose === "waiter" ? null : (
					<header className='modal-card-head'>
						<p className='modal-card-title'>{title}</p>
						<IconButton
							icon={<CloseIconDark />}
							onClick={() => dispatch(paymentOptionsModalReducer())}
						/>
					</header>
				)}
				<section className={`modal-card-body `}>
					<div className='content'>{children}</div>
				</section>
			</div>
		</div>
	);
};
export default Modal;
