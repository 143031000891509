// core version + navigation, pagination modules:
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module
// Direct React component imports
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
// Styles must use direct files imports
import "swiper/swiper.scss"; // core Swiper
import { useDispatch } from "react-redux";
import { activeCategoryReducer } from "store/app/appSlice";
import { useCategories } from "hooks/useCategories";
import { Spinner } from "assets";

const Categories = () => {
	const dispatch = useDispatch();

	const { isLoading, data } = useCategories();

	return (
		<>
			<h6 className='has-text-weight-semibold mb-2'>Categories</h6>
			{isLoading ? (
				<Spinner />
			) : (
				<Swiper spaceBetween={50} slidesPerView={3.5}>
					{data?.data?.map((category) => (
						<SwiperSlide key={category.id}>
							<button
								className='button is-rounded py-0'
								style={{ width: "95%" }}
								onClick={() => dispatch(activeCategoryReducer(category.value))}>
								{category.name}
							</button>
						</SwiperSlide>
					))}
				</Swiper>
			)}
		</>
	);
};

export default Categories;
