import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	activeCategory: "beef",
	isTopbarOpen: JSON.parse(localStorage.getItem("isTopbarOpen")) ?? true,
};

export const appSlice = createSlice({
	name: "app",
	initialState,
	reducers: {
		//? this functions will be triggered by dispatch() whenever APP does any operation related to authentication
		activeCategoryReducer: (state, action) => {
			state.activeCategory = action.payload;
			//APP item related logic goes here
		},

		// top bar reducer
		topBarReducer: (state) => {
			state.isTopbarOpen = !state.isTopbarOpen;
			JSON.stringify(localStorage.setItem("isTopbarOpen", state.isTopbarOpen));
		},
	},
});

// here we are basically exposing APP STATE's reducers  for use by components using the dispatch() from useDispatch hook
export const { activeCategoryReducer, topBarReducer } = appSlice.actions;

// here we are basically exposing APP STATE for use by components using the useSelector Hook
export const reduxDataApp = (state) => state.app;

export default appSlice.reducer;
