import { EnglishFlag } from "assets";

const LanguageDropdown = () => {
	return (
		<div className='column is-5'>
			<div className='control has-icons-left'>
				<div className='select'>
					<select>
						<option>EN</option>
						<option>Arabic</option>
					</select>
				</div>
				<span className='icon is-left'>
					<EnglishFlag />
				</span>
			</div>
		</div>
	);
};

export default LanguageDropdown;
