const API_ENDPOINTS = {
	// api for post
	BACKEND_SERVER_ADDRESS: "https://coco-restaurant-api.herokuapp.com/api/v1/",
	/* ADMIN RELATED ENDPOINTS */
	LOGIN_ADMIN: "login/access-token",
	PRODUCTS: "products/",
	CATEGORIES: "categories/",
	CALL_WAITERS: "call_waiters/",
	RESERVATIONS: "reservations/",
	ORDERS: "orders/",
};
export default API_ENDPOINTS;
