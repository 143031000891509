import React from "react";

const LargeButton = ({ btnText, color = "primary" }) => (
	<>
		<div className='columns is-flex m-0 is-justify-content-center px-3'>
			<button
				className={`has-background-${color} p-4`}
				style={{ width: "100%", borderRadius: "20px", border: "none" }}>
				<div className='columns is-flex m-0 is-justify-content-center'>
					<h6 className='is-size-6 '>{btnText}</h6>
				</div>
			</button>
		</div>
		<br />
	</>
);

export default LargeButton;
