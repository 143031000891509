import { CallWaiterButton, WelcomeHeader } from "common";
import colors from "styles/exports.module.scss";
import { SearchIcon } from "assets";
import Categories from "./Categories";
import Topbar from "./Topbar";
import { reduxDataCart } from "store/cart/cartSlice";
import { useSelector } from "react-redux";
import { reduxDataApp } from "store/app/appSlice";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
	const navigate = useNavigate();
	const { cartItems } = useSelector(reduxDataCart);
	const { isTopbarOpen } = useSelector(reduxDataApp);

	return (
		<section style={{ height: `${isTopbarOpen ? "375px" : "282px"}` }}>
			<nav
				className='navbar is-fixed-top'
				style={{
					backgroundColor: colors.backgroundColor,
					display: "block",
				}}>
				{isTopbarOpen && <Topbar />}

				{/* welcome text and language selection dropdown */}
				<WelcomeHeader />
				{/* search and call waiter button */}
				<div className='columns px-3 is-mobile is-flex is-align-items-center'>
					<div className='column is-9'>
						<div className='field'>
							<p className='control is-small has-icons-left'>
								<input
									className='input is-rounded has-background-info'
									type='text'
									placeholder='Search'
								/>
								<span className='icon is-left'>
									<SearchIcon />
								</span>
							</p>
						</div>
					</div>
					<div className='column'>
						<CallWaiterButton />
					</div>
				</div>

				{/* cart status  */}

				<div className='columns is-mobile has-background-white mx-3 px-3 py-2 primary-border-radius'>
					<div className='column'>
						<h6>
							{cartItems.length === 0
								? "Your Cart is Empty"
								: `${cartItems.length} Item in your cart`}
						</h6>
					</div>

					<div className='column  is-flex is-justify-content-flex-end '>
						<a
							href
							onClick={() => {
								cartItems.length !== 0 && navigate("/checkout");
							}}>
							<h6 className='has-text-primary'>
								{cartItems.length === 0 ? `ORDER NOW` : `VIEW`}
							</h6>
						</a>
					</div>
				</div>

				{/* Categories */}
				<div className='pl-4 pb-4'>
					<Categories />
				</div>
			</nav>
		</section>
	);
};

export default Navbar;
