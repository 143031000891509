import useAuth from "hooks/useAuth";
import { stringify } from "querystring";
import useReservation from "hooks/useReservation";
import { Spinner } from "assets";

const LoginPage = () => {
	const { useReserveTable } = useReservation();

	const { useLogin, isLoggedIn } = useAuth();

	let userData = stringify({
		username: "admin@coco-restaurant-api.com",
		password: "coco-restaurant",
	});

	useLogin({
		userData,
		isUserLoggedIn: isLoggedIn,
	});

	useReserveTable({
		tableData: { table_id: 2, capacity: 4, status: 0 },
		isUserLoggedIn: isLoggedIn,
	});

	return (
		<section className='hero is-primary is-fullheight'>
			<div className='hero-body'>
				<div className='container'>
					<div className='columns is-centered'>
						<div className='column is-5-tablet is-4-desktop is-3-widescreen'>
							<form className='box'>
								<h4>Please wait ... </h4>
								<Spinner />
							</form>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default LoginPage;
