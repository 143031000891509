import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	cartItems: [],
	customise: {},
};

export const cartSlice = createSlice({
	name: "cart",
	initialState,
	reducers: {
		//? this functions will be triggered by dispatch() whenever user adds items in cart
		cartItemsReducer: (state, action) => {
			state.cartItems = action.payload;
		},

		//? this functions will be triggered by dispatch() whenever user clicks customise button
		customiseItemReducer: (state, action) => {
			state.customise = action.payload;
		},
	},
});

//! here we are basically exposing CART STATE's reducers  for use by components using the dispatch() from useDispatch hook
export const { cartItemsReducer, customiseItemReducer } = cartSlice.actions;

//! here we are basically exposing CART STATE for use by components using the useSelector Hook
export const reduxDataCart = (state) => state.cart;
export default cartSlice.reducer;
