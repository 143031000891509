import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	reservationStatus:
		JSON.parse(localStorage.getItem("reservation_status")) ?? {},
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		//? this functions will be triggered by dispatch() whenever APP does any operation related to authentication
		reservedTableReducer: (state, action) => {
			state.reservationStatus = action.payload;
			localStorage.setItem(
				"reservation_status",
				JSON.stringify(action.payload)
			);
			//APP item related logic goes here
		},
	},
});

// here we are basically exposing APP STATE's reducers  for use by components using the dispatch() from useDispatch hook
export const { reservedTableReducer } = userSlice.actions;

// here we are basically exposing APP STATE for use by components using the useSelector Hook
export const reduxDataUser = (state) => state.user;

export default userSlice.reducer;
