import React from "react";
import IconButton from "./IconButton";
import { AddIcon, SubtractIcon } from "assets";
import { useDispatch } from "react-redux";

const PlusMinusPair = ({ count, plusFunction, minusFunction }) => {
	const dispatch = useDispatch();
	return (
		<div className='column pt-1 pb-1 pl-0 pr-0 is-7 mr-2	has-background-info is-flex is-align-items-center is-justify-content-space-evenly add-border-radius'>
			<IconButton
				customSizing
				color='primary'
				icon={<SubtractIcon />}
				onClick={() => dispatch(minusFunction)}
			/>
			{count}
			<IconButton
				customSizing
				color='primary'
				icon={<AddIcon />}
				onClick={() => dispatch(plusFunction)}
			/>
		</div>
	);
};

export default PlusMinusPair;
