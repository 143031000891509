import LanguageDropdown from "./LanguageDropdown";

const WelcomeHeader = () => {
	return (
		<div className='columns px-3 m-0 pt-2 is-mobile is-flex is-align-items-center'>
			<div className='column is-7'>
				<h6>Welcome to MilkBun</h6>
			</div>
			<LanguageDropdown />
		</div>
	);
};

export default WelcomeHeader;
