import API_ENDPOINTS from "constants/API_ENDPOINTS";

import { useDispatch, useSelector } from "react-redux";
import { loginReducer, reduxDataAuth } from "store/auth/authSlice";
import { request } from "utils/axios";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

const useAuth = () => {
	const navigate = useNavigate();
	const { isLoggedIn } = useSelector(reduxDataAuth);
	const dispatch = useDispatch();

	// login function
	const logUserIn = (data) =>
		request({ url: API_ENDPOINTS.LOGIN_ADMIN, method: "post", data });

	// success callback of login function

	const onSuccess = (res) => {
		dispatch(
			loginReducer({
				isLoggedIn: true,
				tokens: res.data,
			})
		);
	};

	// error callback of reservation function

	const onError = () => {
		navigate("/login");
	};

	const useLogin = ({ userData, isUserLoggedIn }) =>
		useQuery("log-user-in", () => logUserIn(userData), {
			onSuccess,
			onError,
			enabled: isUserLoggedIn === false,
		});

	// logout function
	const logout = () => {
		dispatch(
			loginReducer({
				isLoggedIn: false,
				tokens: {},
				adminProfile: {},
			})
		);
		localStorage.clear();
	};

	return {
		isLoggedIn,
		useLogin,
		logout,
	};
};
export default useAuth;
