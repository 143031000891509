import React from "react";
import ReactDOM from "react-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import App from "./App";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { STORE } from "store/Store";
import "styles/styles.scss";
const client = new QueryClient();
ReactDOM.render(
	<React.StrictMode>
		<Provider store={STORE}>
			<QueryClientProvider client={client}>
				<App />
				<ReactQueryDevtools />
			</QueryClientProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);
